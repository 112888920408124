import { useEffect, useState, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next'
import { Button } from '@mui/material';
import axios from "axios";

import './File.css';

import imageFire from './image/local_fire_department_black_24dp.svg';
import imageFileText from './image/description_black_24dp.svg';
import imageFileImage from './image/image_black_24dp.svg';
import imageFileAudio from './image/audiotrack_black_24dp.svg';
import imageFileVideo from './image/smart_display_black_24dp.svg';
import imageFileArchive from './image/folder_zip_black_24dp.svg';
import imageFileEtc from './image/insert_drive_file_black_24dp.svg';

function File() {
  const { t }  = useTranslation(['page']);
  const { codeOrRemindWords } = useParams();
  const [ active, setActive ] = useState(false);
  const [ already, setAlready ] = useState(false);
  const [ info, setInfo ] = useState({});
  const navigate = useNavigate();
  const imgRef = useRef(null);

  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + sizes[i];
  }

  useEffect(() => {
    axios({
      method: 'GET',
      url: `https://api.file.moe/${codeOrRemindWords}/info`
    })
    .then(({ data }) => {
      if (!data.success) {
        throw new Error(data.error.code);
      }

      setActive(data.active);
      setInfo(data.info);
    })
    .catch(error => {
      const errorString = error.toString();
      let errorCode = "ERRUNK";
      if (/ERR[0-9]{3}/.test(errorString)) {
        const match = errorString.match(/(ERR[0-9]{3})/);
        errorCode = match[1];
      }
      navigate(`/error?error=${errorCode}`);
    });
  }, [codeOrRemindWords, navigate]);

  useEffect(() => {
    if (info.mime?.includes('text')) {
      imgRef.current.src = imageFileText;
    } else if (info.mime?.includes('image')) {
      imgRef.current.src = imageFileImage;
    } else if (info.mime?.includes('audio')) {
      imgRef.current.src = imageFileAudio;
    } else if (info.mime?.includes('video')) {
      imgRef.current.src = imageFileVideo;
    } else if (['zip', 'rar', 'gzip', 'tar', '7z', 'compressed'].some(o => info.mime?.includes(o))) {
      imgRef.current.src = imageFileArchive;
    } else {
      imgRef.current.src = imageFileEtc;
    }
  }, [info]);

  const goHome = () => {
    navigate('/');
  }

  const downloadStart = () => {
    if (active) {
      window.open(`https://api.file.moe/${info.code}`, '_blank');
      setAlready(true);
    }
  }

  return (
    <div className="File">
      <main>
        <div className='logo-box' onClick={goHome}>
          <img src={imageFire} alt="FILE.MOE Logo" />
          <label>FILE.MOE</label>
        </div>
        <div className='file-box'>
          <div className='icon'>
            <img src={imageFileEtc} ref={imgRef} />
          </div>
          <div className='info'>
            <div className='title'>
              <label title={ info.name }>{ info.name }</label>
            </div>
            <div className='size'>
              <label>{ formatBytes(info?.size ?? 0) }</label>
            </div>
            <div className='download'>
              <Button variant="contained" onClick={downloadStart} disabled={ !(active && !already) }>{ active ? ( already ? 'Downloaded' : 'Download') : 'Expired' }</Button>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}

export default File;