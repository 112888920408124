import { LinearProgress } from '@mui/material';
import moment from 'moment';

import './Upload.css';

function Upload({ file, startTime, progress }) {
  return (
    <div className='Upload'>
      <div className='info'>
        <div className='name' title={ file.name }>
          { file.name }
        </div>
        <div className='time'>
          { moment.utc(Math.ceil((100 - progress) / (progress / (Date.now() - startTime)))).format('mm:ss') }
        </div>
      </div>
      <LinearProgress variant="determinate" value={progress} />
    </div>
  );
}

export default Upload;