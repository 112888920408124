import { useTranslation } from 'react-i18next'

import './Policy.css';

function Policy() {
  const { t }  = useTranslation(['page']);

  return (
    <div className='Policy'>
      <h2>{ t('termsAndPolicies') }</h2>
      <div>
        <p>
          { t('policy0-1') }<br />
          { t('policy0-2') }
        </p>
      </div>
      <div>
        <h3>{ t('policy1') }</h3>
        <ul>
          <li>{ t('policy1-1') }</li>
          <li>{ t('policy1-2') }<span className="smallText">{ t('policy1-2-ex') }</span></li>
          <li>{ t('policy1-3') }</li>
        </ul>
        <p>
          { t('policy1-desc') }
        </p>
      </div>
      <div>
        <h3>{ t('policy2') }</h3>
        <ul>
          <li>{ t('policy2-1') }</li>
          <li>{ t('policy2-2') }</li>
          <li>{ t('policy2-3') }</li>
        </ul>
        <p>
          { t('policy2-desc') }
        </p>
      </div>
      <div>
        <h3>{ t('policy3') }</h3>
        <ul>
          <li>IP</li>
          <li>User-Agent</li>
        </ul>
        <p>
          { t('policy3-desc') }
        </p>
      </div>
      <div>
        
      </div>
    </div>
  )
}

export default Policy;