import { useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import './Created.css';

import imageFire from './image/local_fire_department_black_24dp.svg';
import imageCopy from './image/content_copy_black_24dp.svg';

function Created() {
  const { state } = useLocation();
  const navigate = useNavigate();
  const { t }  = useTranslation(['page']);
  const codeBarRef = useRef(null);
  const remindWordsBarRef = useRef(null);

  const codeLink = 'http://file.moe/' + state.code;
  const remindWordsLink = 'http://file.moe/' + state.remindWords;

  const goHome = () => {
    navigate('/');
  }

  const copyCode = () => {
    navigator.clipboard.writeText(codeLink)
    .then(() => {
      toast.success(t('copiedToClipboard'), { autoClose: 1000 });
      codeBarRef.current.className = "link-bar active";
      setTimeout(() => {
        codeBarRef.current.className = "link-bar";
      }, 200);
    });
  }

  const copyRemindWords = () => {
    navigator.clipboard.writeText(remindWordsLink)
    .then(() => {
      toast.success(t('copiedToClipboard'), { autoClose: 1000 });
      remindWordsBarRef.current.className = "link-bar active";
      setTimeout(() => {
        remindWordsBarRef.current.className = "link-bar";
      }, 200);
    });
  }

  return (
    <div className='Created'>
      <main>
        <div className='logo-box' onClick={goHome}>
          <img src={imageFire} alt="FILE.MOE Logo" />
          <label>FILE.MOE</label>
        </div>
        <div className='link-box'>
          <div className='link-bar' ref={codeBarRef}>
            <input type='text' value={codeLink} readOnly="readOnly" />
            <img src={imageCopy} alt="code button" onClick={copyCode}/>
          </div>
        </div>
        <div className='link-box'>
          <div className='link-bar' ref={remindWordsBarRef}>
            <input type='text' value={remindWordsLink} readOnly="readOnly" />
            <img src={imageCopy} alt="remind words button" onClick={copyRemindWords}/>
          </div>
        </div>
        <div className='desc-box'>
          <label>{ t('descSameLink') }</label>
        </div>
      </main>
      <ToastContainer
        position="top-right"
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
}

export default Created;