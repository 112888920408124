import { BrowserRouter, Routes, Route } from 'react-router-dom';

import Home from './page/Home';
import Created from './page/Created';
import File from './page/File';
import Error from './page/Error';

import './App.css';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route index element={<Home />} />
        <Route path="/created" element={<Created />} />
        <Route path="/error" element={<Error />} />
        <Route path="/:codeOrRemindWords" element={<File />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;