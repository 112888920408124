import { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDropzone } from 'react-dropzone';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Modal from 'react-modal';
import axios from "axios";

import Upload from './Upload';
import Policy from './Policy';

import './Home.css';

import imageFire from './image/local_fire_department_black_24dp.svg';
import imageSearch from './image/search_black_24dp.svg';

function Home() {
  const { t }  = useTranslation(['page']);
  const [textSearch, setTextSearch] = useState('');
  const [file, setFile] = useState({});
  const [progress, setProgress] = useState(0);
  const [startTime, setStartTime] = useState(0);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const navigate = useNavigate();
  const wrapperRef = useRef(null);
  const searchBarRef = useRef(null);
  const inputRef = useRef(null);
  const uploadBoxRef = useRef(null);
  const {acceptedFiles, getRootProps, getInputProps} = useDropzone();

  useEffect(() => {
    if (acceptedFiles.length > 0) {
      const acceptedFile = acceptedFiles[0];
      if (acceptedFile.size > 16 * 1024 * 1024 * 1024) {
        toast.warning(t('sizeTooLarge'), { autoClose: 2000 });
        return;
      }

      setFile(acceptedFile);

      const bodyFormData = new FormData();
      bodyFormData.append('target', acceptedFile);

      setStartTime(Date.now());

      wrapperRef.current.style.display = 'none';
      uploadBoxRef.current.style.display = '';

      axios({
        method: 'POST',
        url: 'https://api.file.moe',
        data: bodyFormData,
        headers: { "Content-Type": "multipart/form-data" },
        onUploadProgress: (progressEvent) => {
          const percentage = (progressEvent.loaded / progressEvent.total) * 100;
          setProgress(percentage);
        }
      })
      .then(({ data }) => {
        if (!data.success) {
          throw new Error(data.error.code);
        }
  
        navigate('/created', { state: data });
      })
      .catch(error => {
        const errorString = error.toString();
        let errorCode = "ERRUNK";
        if (/ERR[0-9]{3}/.test(errorString)) {
          const match = errorString.match(/(ERR[0-9]{3})/);
          errorCode = match[1];
        }
        navigate(`/error?error=${errorCode}`);
      })

    }
  }, [acceptedFiles, t]);

  const findFileInfo = () => {
    if (!textSearch) {
      toast.warning(t('emptySearchBar'), { autoClose: 2000 });
      searchBarRef.current.className = "search-bar active";
      inputRef.current.focus();
      setTimeout(() => {
        searchBarRef.current.className = "search-bar";
      }, 200);
      return;
    }

    axios({
      method: 'GET',
      url: `https://api.file.moe/${textSearch}/info`
    })
    .then(({ data }) => {
      if (data.success) {
        navigate(`/${textSearch}`);
      } else {
        toast.warning(t('notFoundTextSearch'), { autoClose: 2000 });
        searchBarRef.current.className = "search-bar active";
        inputRef.current.focus();
        setTimeout(() => {
          searchBarRef.current.className = "search-bar";
        }, 200);
      }
    })
    .catch(error => {
      const errorString = error.toString();
      let errorCode = "ERRUNK";
      if (/ERR[0-9]{3}/.test(errorString)) {
        const match = errorString.match(/(ERR[0-9]{3})/);
        errorCode = match[1];
      }
      navigate(`/error?error=${errorCode}`);
    });
  }

  const handleTextSearch = (event) => {
    setTextSearch(event.target.value.trim());
  }

  const handleTextSearchEnter = (event) => {
    if (event.key === "Enter") {
      findFileInfo();
    }
  }

  useEffect(() => {
    inputRef.current.focus();
  }, []);
  
  Modal.setAppElement('#root');

  const customModalStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
    },
  };

  const openModal = () => {
    setModalIsOpen(true);
  }

  const closeModal = () => {
    setModalIsOpen(false);
  }

  return (
    <div className='Home'>
      <main>
        <div className='logo-box'>
          <img src={imageFire} alt="FILE.MOE Logo" />
          <label>FILE.MOE</label>
        </div>
        <div className='wrapping-box' ref={wrapperRef}>
          <div className='search-box'>
            <div className='box-title'>
              <label>{ t('findFile') }</label>
            </div>
            <div className='search-bar' ref={searchBarRef}>
              <input
                type='text'
                placeholder='ring-space-snow | q0URR'
                value={textSearch}
                onChange={handleTextSearch}
                onKeyDown={handleTextSearchEnter}
                ref={inputRef}
              />
              <img src={imageSearch} alt="link button" onClick={findFileInfo} />
            </div>
          </div>
          <div className='file-box'>
            <div className='box-title'>
              <label>{ t('shareANewFile') }</label>
            </div>
            <section className="container">
              <div {...getRootProps({className: 'dropzone'})}>
                <input {...getInputProps()} />
                <p>Drag 'n' drop | Click</p>
              </div>
            </section> 
          </div>
        </div>
        <div className='upload-box' ref={uploadBoxRef} style={{display: 'none'}} >
          <Upload file={file} startTime={startTime} progress={progress} />
        </div>
        <div className='desc-box'>
          <label onClick={openModal}>{ t('termsAndPolicies') }</label>
        </div>
      </main>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customModalStyles}
        contentLabel="Policy Modal"
      >
        <Policy />
      </Modal>
      <ToastContainer
        position="top-right"
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
}

export default Home;